import React, { useEffect } from "react";
import bgImage from "../Assets/Images/technology.png";
import { Link } from "react-router-dom";

function Technology() {


  return (
    <div className="container-fluid w-screen mx-auto relative h-screen lg:pb-16  flex flex-col justify-between">
      <div
        data-aos="fade-in"
        data-aos-delay="1000"
        data-aos-easing="ease-in-out"
        className="relative bg-cover bg-center full h-[60%]  text-white"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className=" z-10 absolute bottom-0 md:bottom-1 md:left-5 rounded px-4">
          <div className="md:w-5/6">
            <div className="flex flex-row">
              <div
                data-aos="new-up"
                data-aos-delay="1200"
                data-aos-easing="ease-in-out"
              >
                <h2 className="text-3xl text-[#DDA556] ">Technology </h2>
              </div>
            </div>
            <div
              data-aos="new-left"
              data-aos-delay="1800"
              data-aos-easing="ease-in-out"
            >
              <p className="mt-4 text-[#7a7a7a] md:text-[16px] text-[14px] text-justify">
                At Mark Anthony Real Estate, we leverage cutting-edge technology
                to enhance your real estate experience. Our innovative tools and
                data-driven insights ensure you make informed decisions quickly
                and efficiently. Stay ahead with our seamless, tech-enabled
                solutions.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col hidden lg:block md:flex-col bg-black px-4">
        <div
          className="order-2 md:order-1 text-center md:mt-5 py-5"
          data-aos="new-up"
          data-aos-delay="2500"
          data-aos-easing="ease-in-out"
        >
          <h3
            className="text-lg md:text-2xl text-[#ecd0a8] md:tracking-[0.8em] tracking-[0.4em]"
            style={{ fontFamily: "Bodoni MT, serif" }}
          >
            MARKANTHONY
          </h3>
        </div>
        <Link to="/" className="order-1 md:order-2">
          <div
            className="flex justify-end lg:justify-start md:justify-start"
            data-aos="new-up"
            data-aos-delay="3000"
            data-aos-easing="ease-in-out"
          >
            <button className="group bottom-5 md:left-5 text-3xl text-[#DDA556] font-light py-2 rounded relative md:mt-2 mt-10 flex justify-end">
              <span className="inline-block">
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip">
                  B
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-100">
                  a
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-200">
                  c
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-300">
                  k
                </span>
              </span>
            </button>
          </div>
        </Link>
      </div>
      <div className="flex flex-col block lg:hidden md:flex-col bg-black px-4">
        <div
          className="order-2 md:order-1 text-center md:mt-5 py-5"
   
        >
          <h3
            className="text-lg md:text-2xl text-[#ecd0a8] md:tracking-[0.8em] tracking-[0.4em]"
            style={{ fontFamily: "Bodoni MT, serif" }}
          >
            MARKANTHONY
          </h3>
        </div>
        <Link to="/" className="order-1 md:order-2">
          <div
            className="flex justify-end lg:justify-start md:justify-start"
            data-aos="new-up"
            data-aos-delay="3000"
            data-aos-easing="ease-in-out"
          >
            <button className="group bottom-5 md:left-5 text-3xl text-[#DDA556] font-light py-2 rounded relative md:mt-2 mt-10 flex justify-end">
              <span className="inline-block">
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip">
                  B
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-100">
                  a
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-200">
                  c
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-300">
                  k
                </span>
              </span>
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Technology;
