import React, { useEffect, useRef, useState } from "react";
import Banner from "./Banner";
import WhoWeAre from "./WhoWeAre";
import VibeCheck from "./VibeCheck";
import ConnectandCollaborate from "./ConnectandCollaborate";
import Jointheteam from "./Jointheteam";
import Ourarchitecture from "./Ourarchitecture";
import PowerPartners from "./PowerPartners";
import Footer from "./Footer";
import './home.css'; 
import frame from "../Assets/Images/Frame.png"

const Home = () => {
  const [showDream, setShowDream] = useState(false);
  const [animatedElements, setAnimatedElements] = useState({});
  const [showSVG, setShowSVG] = useState(false);

  // const bannerRef = useRef(null);
  const whoRef = useRef(null);
  const vibeRef = useRef(null);
  const connectRef = useRef(null);
  const joinRef = useRef(null);
  const archRef = useRef(null);
  const powerRef = useRef(null);
  const svgRef = useRef(null);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDream(true);
    }, 10200);

    return () => clearTimeout(timer);
  }, []);
  // Border animation 
  const animateBorder = (refName) => {
    setAnimatedElements((prevState) => ({
      ...prevState,
      [refName]: true,
    }));
    if (refName !== "connectRef") {
      setTimeout(() => {
        setAnimatedElements((prevState) => ({
          ...prevState,
          [refName]: false,
        }));
      }, 7000);
    }
  };

  const resetBorderAnimation = (refName) => {
    setAnimatedElements((prevState) => ({
      ...prevState,
      [refName]: false,
    }));
  };

  // scroll to top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  useEffect(() => {
    const element = connectRef.current;
    if (!element) return;

    const handleAnimationEnd = () => {
      setShowSVG(true);
    };

    element.addEventListener("animationend", handleAnimationEnd);

    return () => {
      element.removeEventListener("animationend", handleAnimationEnd);
    };
  }, []);

  useEffect(() => {});

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (connectRef.current) {
        connectRef.current.classList.remove("animate-border");
        // setShowSVG(false);
      }
    }, 5000);

    return () => clearTimeout(timeout);
  }, [showSVG]);
  return (
    <div className="App bg-black">
      <div>
        <Banner />
      </div>
      <div className="container bg-black px-4 lg:px-8 font-oswald mt-2 py-1 lg:py-6">
        <div className="flex flex-row">
          <div className="relative text-[#DDA556] bg-black -mb-2 text-2xl font-mono z-20 flex flex-row">
            {showDream && ["D", "r", "e", "a", "m"].map((letter, index) => (
              <div
                key={index}
                className="letter-animation"
                style={{ animationDelay: `${(4 - index) * 100}ms` }} 
              >
                <h2>{letter}</h2>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container-fluid mx-auto px-4">
        <div
          className={`who-we-are bordered-element-whoweare ${
            animatedElements.whoRef ? "animate-border-whoweare" : ""
          }`}
          ref={whoRef}
          onMouseEnter={() => animateBorder("whoRef")}
          onMouseLeave={() => resetBorderAnimation("whoRef")}
          onTouchStart={() => animateBorder("whoRef")}
        >
          <WhoWeAre />
        </div>
      </div>
      <div className="container-fluid mx-auto px-4 py-8 lg:py-16">
        <div
          className={`vibe-check bordered-element1 ${
            animatedElements.vibeRef ? "animate-border-vibecheck" : ""
          }`}
          ref={vibeRef}
          onMouseEnter={() => animateBorder("vibeRef")}
          onMouseLeave={() => resetBorderAnimation("vibeRef")}
          onTouchStart={() => animateBorder("vibeRef")}
        >
          <VibeCheck />
        </div>
        </div>
        <div className="container-fluid mx-auto px-4">
        <div
          className={`power-partners bordered-element2 ${
            animatedElements.powerRef ? "animate-border-vibecheck1" : ""
          }`}
          ref={powerRef}
          onMouseEnter={() => animateBorder("powerRef")}
          onMouseLeave={() => resetBorderAnimation("powerRef")}
          onTouchStart={() => animateBorder("powerRef")}
        >
          <PowerPartners />
        </div>
      </div>
      <div className="container-fluid mx-auto pt-8 lg:py-16 ">
        <div
          className={`our-architecture bordered-element3 ${
            animatedElements.archRef ? "animate-border-vibecheck" : ""
          }`}
          ref={archRef}
          onMouseEnter={() => animateBorder("archRef")}
          onMouseLeave={() => resetBorderAnimation("archRef")}
          onTouchStart={() => animateBorder("archRef")}
        >
          <Ourarchitecture />
        </div>
      </div>
      <div className="container-fluid mx-auto px-4 -mt-36 md:mt-1">
        <div
          className={`join-the-team bordered-element4 ${
            animatedElements.joinRef ? "animate-border-vibecheck1" : ""
          }`}
          ref={joinRef}
          onMouseEnter={() => animateBorder("joinRef")}
          onMouseLeave={() => resetBorderAnimation("joinRef")}
          onTouchStart={() => animateBorder("joinRef")}
        >
          <Jointheteam />
        </div>
      </div>
      <div
        className="container px-4 mx-auto max-w-full bg-black pt-12 lg:pt-16 pb-6 "
        id="Connect"
      >
        <div
          className={`connect-and-collaborate bordered-element ${
            animatedElements.connectRef ? "animate-border" : ""
          }`}
          ref={connectRef}
          onMouseEnter={() => animateBorder("connectRef")}
          onMouseLeave={() => resetBorderAnimation("connectRef")}
          onTouchStart={() => animateBorder("connectRef")}
        >
          <ConnectandCollaborate />
          {showSVG && (
            <img src={frame}  onClick={scrollToTop} className="absolute -bottom-[33px] animate-pulse text-[#dda556] text-xl mb-[-32px] cursor-pointer"></img>
            // <svg
            //   onClick={scrollToTop}
            //   fill="none"
            //   stroke="currentColor"
            //   strokeLinecap="round"
            //   strokeLinejoin="round"
            //   strokeWidth={0.5}
            //   viewBox="0 0 24 24"
            //   height="3em"
            //   width="3em"
            //   className="absolute -bottom-[33px] animate-pulse text-[#dda556] text-xl mb-[-24px] cursor-pointer"
            // >
            //   <path stroke="none" d="M0 0h24v24H0z" />
            //   <path d="M5 21V7l8-4v18M19 21V11l-6-4M9 9v.01M9 12v.01M9 15v.01M9 18v.01" />
            // </svg>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
