import React, { useEffect, useRef, useState } from "react";
import "flatpickr/dist/flatpickr.css";
import flatpickr from "flatpickr";
import { AiOutlineClockCircle } from "react-icons/ai";
import Swal from "sweetalert2";
import Video from "../Assets/Videos/Connectandcolab_Final.mp4";
import { FiAlertCircle } from "react-icons/fi";
import axios from "axios";

const ConnectandCollaborate = () => {
  const inputRef = useRef(null);
  const iconRef = useRef(null);

  const [formData, setFormData] = useState({
    builder_name: "",
    email: "",
    phone: "",
    date_time: "",
  });

  const [errorbuilder_name, setErrorbuilder_name] = useState(false);
  const [erroremail, setErroremail] = useState(false);
  const [errorphone, setErrorphone] = useState(false);
  const [errordate_time, setErrordate_time] = useState(false);
  const getBorderColor = (error) => (error ? "red" : "#dda556");

  useEffect(() => {
    if (inputRef.current) {
      flatpickr(inputRef.current, {
        enableTime: true,
        dateFormat: "Y-m-d h:i K",
        time_24hr: false,
        onChange: (selectedDates, dateStr) => {
          setFormData({ ...formData, date_time: dateStr });
          setErrordate_time(false);
        },
      });
    }

    if (iconRef.current) {
      iconRef.current.addEventListener("click", () => {
        inputRef.current._flatpickr.open();
      });
    }
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    switch (name) {
      case "builder_name":
        setErrorbuilder_name(false);
        break;
      case "email":
        setErroremail(false);
        break;
      case "phone":
        setErrorphone(false);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { builder_name, email, phone, date_time } = formData;

      if (!builder_name) {
        setErrorbuilder_name(true);
        return;
      }
      if (!email) {
        setErroremail(true);
        return;
      }
      if (!phone) {
        setErrorphone(true);
        return;
      }
      if (!date_time) {
        setErrordate_time(true);
        return;
      }
      const response = await axios.post(
        "https://leadapi.homebble.in/formdataRoute/sendMail",
        formData
      );
      Swal.fire("Success", response.data.message, "success");
      setFormData({
        builder_name: "",
        email: "",
        phone: "",
        date_time: "",
      });
    } catch (error) {
      Swal.fire(
        "Error",
        error.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  return (
    <div className="container-fluid px-4">
      <div id="Connect">
        <video
          className="w-full h-[600px] object-cover mb-10"
          autoPlay
          muted
          loop
        >
          <source src={Video} type="video/mp4" />
        </video>
        <div className="absolute inset-0 flex items-center justify-center px-4">
          <div className="text-white p-2 mt-[-50px] max-w-xl">
            <h2
              data-aos="new-up"
              data-aos-delay="850"
              data-aos-easing="ease-in-out"
              className="text-3xl font-normal text-[#DDA556] mb-[25px] text-center"
            >
              Connect and Collaborate
            </h2>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <input
                data-aos="new-left"
                data-aos-delay="1050"
                data-aos-easing="ease-in-out"
                type="text"
                name="builder_name"
                placeholder="Builder name"
                value={formData.builder_name}
                onChange={handleChange}
                style={{
                  borderColor: getBorderColor(errorbuilder_name),
                  borderWidth: "1px",
                  borderStyle: "solid",
                }}
                className="w-full p-3 bg-gray-700 bg-opacity-50 text-white
                  focus:outline-none focus:ring-2 focus:ring-[#dda556]"
              />
              {errorbuilder_name && (
                <p className="bg-red-800 rounded-md flex items-center text-white p-2">
                  <FiAlertCircle className="text-white mr-2 text-lg" />
                  Builder Name Can't be Empty
                </p>
              )}

              <input
                data-aos="new-left"
                data-aos-delay="1150"
                data-aos-easing="ease-in-out"
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                style={{
                  borderColor: getBorderColor(erroremail),
                  borderWidth: "1px",
                  borderStyle: "solid",
                }}
                className="w-full p-3 bg-gray-700 bg-opacity-50 text-white
                  focus:outline-none focus:ring-2 focus:ring-[#dda556]"
              />
              {erroremail && (
                <p className="bg-red-800 rounded-md flex items-center text-white p-2">
                  <FiAlertCircle className="text-white mr-2 text-lg" />
                  Email Can't be Empty
                </p>
              )}

              <input
                data-aos="new-left"
                data-aos-delay="1350"
                data-aos-easing="ease-in-out"
                type="tel"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleChange}
                style={{
                  borderColor: getBorderColor(errorphone),
                  borderWidth: "1px",
                  borderStyle: "solid",
                }}
                className="w-full p-3 bg-gray-700 bg-opacity-50 text-white
                  focus:outline-none focus:ring-2 focus:ring-[#dda556]"
              />
              {errorphone && (
                <p className="bg-red-800 rounded-md flex items-center text-white p-2">
                  <FiAlertCircle className="text-white mr-2 text-lg" />
                  Phone Number Can't be Empty
                </p>
              )}

              <div
                className="relative w-full"
                data-aos="new-left"
                data-aos-delay="1550"
                data-aos-easing="ease-in-out"
              >
                <input
                  type="text"
                  name="date_time"
                  placeholder="Scheduled Time"
                  ref={inputRef}
                  value={formData.date_time}
                  readOnly
                  style={{
                    borderColor: getBorderColor(errordate_time),
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                  className="w-full p-3 bg-gray-700 bg-opacity-50 text-white
                    focus:outline-none focus:ring-2 focus:ring-[#dda556]"
                />
                <span
                  ref={iconRef}
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer text-[#dda556]"
                >
                  <AiOutlineClockCircle size={24} />
                </span>
              </div>
              {errordate_time && (
                <p className="bg-red-800 rounded-md flex items-center text-white p-2">
                  <FiAlertCircle className="text-white mr-2 text-lg" />
                  Date Can't be Empty
                </p>
              )}

              <div className="flex justify-center">
                <button
                  data-aos="left-right"
                  data-aos-delay="3000"
                  data-aos-easing="ease-in-out"
                  type="submit"
                  className="flex items-center justify-center w-2/4 p-3 bg-[#dda556] hover:bg-yellow-700 text-black font-semibold focus:outline-none focus:ring-2 focus:ring-[#dda556]"
                >
                  <span className="mr-6">Submit</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="[#dda556]"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 01.5-.5h11.793l-3.147-3.146a.5.5 0 01.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L13.293 8.5H1.5A.5.5 0 011 8z"
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectandCollaborate;
