import React, { useEffect } from 'react';
import bgImage from '../Assets/Images/images3.png';
import logo from '../Assets/Images/Logo-01.png';

const ComponentA = () => {
 
  return (
    <div id="WhoweAre" className="mr-0 md:mr-6 ">
      <div className="relative py-0 md:pt-5">
        <div
          data-aos="fade-right"
          data-aos-delay="850"
          className="bg-cover bg-center bg-no-repeat w-full rounded-lg lg:h-[550px] md:h-[400px] max-h-[36rem] animate-move-bg"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className="grid gap-4">
            <div className="flex flex-col items-center justify-center w-full lg:mt-[200px] md:mt-[100px]">
              <div className="p-4 rounded-lg">
                <div className="flex flex-row justify-center">
                  {['Who', 'We', 'Are'].map((word, index) => (
                    <h2
                      key={word}
                      data-aos="new-up"
                      data-aos-delay={`${1200 + index * 400}`}
                      data-aos-easing="ease-in-out"
                      className={`text-${index === 0 ? '2xl' : '2xl'} md:text-3xl font-medium text-[#DDA556] ml-2 mt-7`}
                    >
                      {word}
                    </h2>
                  ))}
                </div>
                <div
                  data-aos="discription"
                  data-aos-delay="2600"
                  data-aos-easing="ease-in-out"
                  className="text-center"
                >
                  <p className="mt-2 text-sm md:text-[16px] text-[#7a7a7a]">
                    At <span className="text-[#DDA556]">Mark Anthony</span>,
                    expertise meets innovation in our dynamic environment.
                  </p>
                </div>
                <div
                  data-aos="discription"
                  data-aos-delay="2900"
                  data-aos-easing="ease-in-out"
                  className="text-center"
                >
                  <p className="mt-1 text-sm md:text-[16px] text-[#7a7a7a] font-bold">
                    Here, every voice matters, and success is a team effort.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-end lg:mt-[30px] md:mt-[10px]">
              <div data-aos="zoom-in" data-aos-delay="3000">
                <img src={logo} alt="Logo" className="mb-3 w-[7rem] md:w-44 h-38" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentA;