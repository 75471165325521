import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './HomePage/Home';
import Navbar from './HomePage/Navbar';
import BlogsHome from './Pages/Blogs/BlogsHome';
import InvestThroughUs from './Pages/InvestThroughUs';
import OurCulture from './Pages/OurCulture';
import Portfolio from './Pages/Portfolio';
import Technology from './Pages/Technology';
import Loading from './Loading';
import Bgsound from './Assets/Sounds/New BGM.mp3';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import AOS from 'aos';
import 'aos/dist/aos.css';
const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isZoomingIn, setIsZoomingIn] = useState(false);
  const [isMuted, setIsMuted] = useState(true); // Start with muted by default
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const lottieRef = useRef(null);
  const location = useLocation();

useEffect(() => {

    AOS.init({ once:true,duration: 1000 });
  }, [])

  const handleVideoEnd = () => {
    setTimeout(() => {
      setIsLoading(false);
      setIsZoomingIn(true);
    }, 200);
  };

  const handleUserInteraction = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const toggleMute = () => {
    if (audioRef.current) {
      const isNowMuted = !isMuted;
      audioRef.current.muted = isNowMuted;
      setIsMuted(isNowMuted);
      if (!isNowMuted) {
        lottieRef.current.play();
      } else {
        lottieRef.current.stop();
      }
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.muted = isMuted;
    }
  }, [isMuted]);

  useEffect(() => {
    window.addEventListener('click', handleUserInteraction, { once: true });
    return () => {
      window.removeEventListener('click', handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handlePlay = () => setIsPlaying(true);
      const handlePause = () => setIsPlaying(false);

      audio.addEventListener('play', handlePlay);
      audio.addEventListener('pause', handlePause);

      return () => {
        audio.removeEventListener('play', handlePlay);
        audio.removeEventListener('pause', handlePause);
      };
    }
  }, []);

  const isMainPage = location.pathname === '/';

  return (
    <div className='bg-black'>
      <audio ref={audioRef} src={Bgsound} loop />
      {!isLoading && (
        <button
          onClick={toggleMute}
          className={`fixed bottom-10 right-12 z-10 ${isPlaying ? 'animate-wave' : ''}`}
        >
          {isMuted ? (
            <dotlottie-player
              ref={lottieRef}
              src="https://lottie.host/06c98edf-3a65-4bf9-9f2f-62217f74385d/kps9DCoCtB.json"
              background="transparent"
              speed="1"
              style={{ width: '32px', height: '32px' }}
              direction="1"
              playMode="normal"
            />
          ) : (
            <dotlottie-player
              ref={lottieRef}
              src="https://lottie.host/06c98edf-3a65-4bf9-9f2f-62217f74385d/kps9DCoCtB.json"
              background="transparent"
              speed="1"
              style={{ width: '32px', height: '32px' }}
              direction="1"
              playMode="normal"
              loop 
              autoplay
            />
          )}
        </button>
      )}
      {isLoading ? (
        <Loading onVideoEnd={handleVideoEnd} />
      ) : (
        <div>
          {isMainPage && <Navbar />}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Blogs" element={<BlogsHome />} />
            <Route path='/InvestThroughUs' element={<InvestThroughUs />} />
            <Route path='/OurCulture' element={<OurCulture />} />
            <Route path='/Portfolio' element={<Portfolio />} />
            <Route path='/Technology' element={<Technology />} />
          </Routes>
        </div>
      )}
    </div>
  );
};

export default App;
