import React, { useState, useEffect,useRef } from 'react';
import blogbanner from '../../Assets/Images/Blogpage.png';
import img1 from '../../Assets/Images/Rectangle 771.png';
import img2 from '../../Assets/Images/Rectangle 77 (1).png';
import img3 from '../../Assets/Images/Rectangle 77 (2).png';
import img4 from '../../Assets/Images/Rectangle 77 (3).png';
import img5 from '../../Assets/Images/Rectangle 77 (4).png';
import img6 from '../../Assets/Images/Rectangle 77 (5).png';
import { Link } from 'react-router-dom';

function BlogsHome() {
    const [expandedBlog, setExpandedBlog] = useState(null);
const blogref=useRef()
    const blogPosts = [
        { img: img1, title: ["Average price", "of", "Property", "in Bangalore"], delay: 150 },
        { img: img2, title: ["Luxury", "apartment", "in", "E-City"], delay: 400 },
        { img: img3, title: ["Top", "10", "Builders", "in Bengaluru"], delay: 700 },
        { img: img4, title: ["Upcoming", "apartments", "in", "Bengaluru"], delay: 1100 },
        { img: img5, title: ["Average price", "of", "Property", "in Bengaluru"], delay: 1500 },
        { img: img6, title: ["Average price", "of", "Property", "in Pune"], delay: 2000 },
    ];

 

    const handleBlogClick = (blog) => {
        setExpandedBlog(blog === expandedBlog ? null : blog);
        if(blogref.current){
            blogref.current.scrollIntoView({behavior:"smooth"})
        }    };

    return (
        <div className='mt-[-145px] bg-black'>
            <div
                className="relative bg-cover bg-center h-[38rem] text-[#DDA556] py-10 bg-black"
                style={{ backgroundImage: `url(${blogbanner})` }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="z-10 absolute top-[24rem] sm:bottom-5 sm:left-5 p-5 rounded sm:flex-row items-center w-full">
                    <div className='w-full sm:w-9/12 md:px-5'>
                        <div data-aos="new-animation" data-aos-delay="1950" data-aos-easing="ease-in-out">
                            <h2 className="text-2xl sm:text-3xl text-[#DDA556]">Blogs</h2>
                        </div>
                        <div data-aos="new-animate" data-aos-delay="2250" data-aos-easing="ease-in-out">
                            <p className="mt-4 text-[#afabab] text-[14px] md:text-[16px]">
                                Stay informed with Mark Anthony Real Estate's blog. Our expert insights, market updates, and practical tips keep you ahead in the real estate game. Discover valuable information on buying, selling, investing, and more. Explore our blog and empower your real estate journey.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {expandedBlog && (
                <div className=" mt-5 grid  md:mx-10 grid-cols-1 md:grid-cols-2" ref={blogref}>
                    <div className="md:px-5">
                        <div className="md:h-64 overflow-hidden mb-5">
                            <img alt="content" className="object-cover object-center md:h-full w-[559px]" src={expandedBlog.img} />
                        </div>
                    </div>
                    <div className="md:p-4 bg-black  py-5 -ml-20">
                        <h2 className="text-2xl mb-3 text-[#DDA556]">{expandedBlog.title.join(' ')}</h2>
                        <p className="text-[#afabab] text-[14px] md:text-[16px] ">
                            Here you can add the new title and description for the selected blog post. Customize this content based on the selected blog.
                        </p>
                    </div>
                </div>
            )}

            <div className={`px-4 mt-5 grid gap-4 md:mx-10 ${expandedBlog ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-2 md:grid-cols-2 lg:grid-cols-3'}`}>
                {blogPosts.map((post, index) => (
                    <div
                        key={index}
                        className={`relative ${expandedBlog === post ? 'expanded' : ''}`}
                        onClick={() => handleBlogClick(post)}
                    >
                        <div className="md:h-64 overflow-hidden mb-5" data-aos="fade-right" data-aos-delay={post.delay} data-aos-easing="ease-in-out">
                            <img alt="content" className="object-cover object-center md:h-full w-full" src={post.img} />
                        </div>
                        <div className='flex flex-wrap'>
                            {post.title.map((part, idx) => (
                                <div key={idx} data-aos="new-animation" data-aos-delay={post.delay + 50 * (idx + 1)} data-aos-easing="ease-in-out">
                                    <h2 className={`text-md md:text-xl font-light title-font text-[#DDA556] md:mt-5 ${idx !== 0 ? 'ml-2' : ''}`}>{part}</h2>
                                </div>
                            ))}
                        </div>
                        <p className="text-gray-600 py-2">12/03.2024</p>
                    </div>
                ))}
            </div>

            <div className='flex flex-col md:flex-col'>
                <div className="order-2 md:order-1 text-center mt-5 py-5">
                    <h3 className="text-lg md:text-2xl text-[#ecd0a8] md:tracking-[0.8em] tracking-[0.4em]" style={{ fontFamily: 'Bodoni MT, serif' }}>MARKANTHONY</h3>
                </div>
                <Link to="/" className="order-1 md:order-2">
                    <div className='flex justify-end lg:justify-start md:justify-start'>
                        <button className="group bottom-5 md:left-5 text-3xl text-[#DDA556] py-2 px-8 rounded relative mt-5 flex justify-end">
                            <span className="inline-block">
                                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip">B</span>
                                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-100">a</span>
                                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-200">c</span>
                                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-300">k</span>
                            </span>
                        </button>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default BlogsHome;
