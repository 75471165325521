import React from 'react'
import img from '../Assets/Images/Rectangle-74.png'
import { Link } from 'react-router-dom'

function InvestThroughUs() {
    return (
        <div className='container-fluid mx-auto relative md:h-screen bg-black px-4 md:px-0'>
            <section class="text-gray-600 body-font">
                <div class=" mx-auto flex md:px-5  md:pt-20 md:pb-10 md:flex-row flex-col items-center">
                    <div class="lg:max-w-lg lg:w-full md:w-1/2  mb-10 md:mb-0" data-aos="new-animation" data-aos-delay="1850" data-aos-easing="ease-in-out">
                        <img class="object-cover object-center rounded w-full h-[18rem] md:h-auto" alt="hero" src={img} />
                    </div>
                    <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-start text-start">
                    <div className='flex flex-row'>
                        <div data-aos="new-animation" data-aos-delay="2050" data-aos-easing="ease-in-out"> <h2 className="text-3xl font-medium text-yellow-600 ">Invest </h2></div>
                        <div data-aos="new-animation" data-aos-delay="2250" data-aos-easing="ease-in-out"> <h2 className="text-3xl font-medium text-yellow-600 ml-2">Through</h2></div>
                        <div data-aos="new-animation" data-aos-delay="2450" data-aos-easing="ease-in-out"> <h2 className="text-3xl font-medium text-yellow-600 ml-2">Us</h2></div>

                    </div >
                       <div data-aos="new-animate" data-aos-delay="2650" data-aos-easing="ease-in-out"> 
                       <p class="md:mb-8 mt-6 leading-relaxed md:text-[16px] text-[14px]">Invest confidently with Mark Antony Real Estate. Our expert team provides tailored investment plans and comprehensive support to maximize your returns.
                            Trust us to turn your real estate dreams into profitable ventures. Partner with us and watch your investments grow
                        </p>
                       </div>
                       
                        {/* <div class="flex justify-center">
                            <div data-aos="new-animate" data-aos-delay="2850" data-aos-easing="ease-in-out">
                                <button
                                    type="submit"
                                    className="flex items-center justify-center mt-3 w-[12rem] p-3 bg-[#dda556] hover:bg-yellow-700 text-black font-semibold focus:outline-none focus:ring-2 focus:ring-[#dda556]"
                                >
                                    <span>Invest now</span>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="[#dda556]"
                                        viewBox="0 0 16 16"
                                        height="1em"
                                        width="1em"
                                        className="ml-2 w-6 h-6"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M1 8a7 7 0 1014 0A7 7 0 001 8zm15 0A8 8 0 110 8a8 8 0 0116 0zM4.5 7.5a.5.5 0 000 1h5.793l-2.147 2.146a.5.5 0 00.708.708l3-3a.5.5 0 000-.708l-3-3a.5.5 0 10-.708.708L10.293 7.5H4.5z"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='flex flex-col md:flex-col bg-black px-4'>
                <div className="order-2 md:order-1 text-center md:mt-5 py-5">
                    <h3 className="text-lg md:text-2xl text-[#ecd0a8] md:tracking-[0.8em] tracking-[0.4em] " style={{  fontFamily: 'Bodoni MT, serif' }}>MARK ANTHONY</h3>
                </div>
                <Link to="/" className="order-1 md:order-2">
                    <div className='flex justify-end lg:justify-start md:justify-start'>
                        <button className="group bottom-5 md:left-5 text-xl text-yellow-600 py-2 rounded relative md:mt-2 mt-10 flex justify-end">
                            <span className="inline-block">
                                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip">B</span>
                                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-100">a</span>
                                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-200">c</span>
                                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-300">k</span>
                            </span>
                        </button>
                    </div>
                </Link>
            </div>

            </section>
        </div>
    )
}

export default InvestThroughUs