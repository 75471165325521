import React from "react";
import { Link } from "react-router-dom";
import bgimg from "../Assets/Images/logos/bgimage.png";
import linkedin from "../Assets/Linked iN.png";
import twitter from "../Assets/X.png";
import facebook from "../Assets/FB.png";
import instagram from "../Assets/Insta.png";
const Footer = () => {
  return (
    <div
      className="bg-cover bg-center text-white py-10 px-5"
      style={{ backgroundImage: `url(${bgimg})` }}
    >
      <div className="md:px-16">
        <div className="grid grid-cols-1 md:grid-cols-3 text-center md:text-left">
          <div className="flex flex-col justify-center items-center md:items-start mb-5 md:mb-0">
            <h2 className="text-xl font-semibold mb-2">Our Address</h2>

            <Link
              to="https://maps.app.goo.gl/zR5ATiMUgKscV7y88"
              target="_blank"
            >
              5th floor 304, Oxford Chambers,  near Manipal Hospital Internal Road, Murugeshpalya, Bengaluru, Karnataka 560017
            </Link>
          </div>
          <div className="flex flex-col justify-center items-center mb-5 md:mb-0"></div>
          <div className="flex flex-col justify-center items-center md:items-end mr-0 lg:mr-4">
            <div className="flex space-x-5">
              <Link
                to= "https://in.linkedin.com/company/markanthonyventures"
               

                target="_blank"
              >
                <img src={linkedin} alt="LinkedIn" className="h-8 w-8" />
              </Link>
              <Link to="https://x.com/MandAVentures"  target="_blank">
                <img src={twitter} alt="Twitter" className="h-8 w-8" />
              </Link>
              <Link
                to="https://www.facebook.com/MAMarkAnthonyVentures/"
                target="_blank"
              >
                <img src={facebook} alt="Facebook" className="h-8 w-8" />
              </Link>
              <Link
                to= "https://www.instagram.com/ma_ventures/"
                target="_blank"
              >
                <img src={instagram} alt="Instagram" className="h-8 w-8" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
