import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Logo from "../Assets/Images/Logo.png";
import "./nav.css"; // Ensure this path is correct

const initialNavigation = [
  { name: "Who We Are", href: "WhoweAre", current: false },
  {
    name: "Our Scoop",
    children: [
      { name: "Our Culture", href: "/OurCulture" },
      { name: "Portfolio", href: "/Portfolio" },
      { name: "Technology", href: "/Technology" },
    ],
  },
  { name: "Blogs", href: "/Blogs", current: false },
  { name: "Join The Squad", href: "JoinTheSquad", current: false },
  { name: "Connect", href: "Connect", current: false },
];

const Navbar = () => {
  const [showLogo, setShowLogo] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showChildnav, setShowChildnav] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const timer1 = setTimeout(() => setShowLogo(true), 2000);
    const timer2 = setTimeout(() => setShowButtons(true), 4000);
    const timer3 = setTimeout(() => setShowChildnav(true), 6000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, []);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  return (
    <nav className="text-white">
      <div className="container-fluid mx-auto px-8 py-4 flex items-center justify-between relative z-20">
        <div
          className={`transition-opacity duration-1000 ${
            showLogo ? "opacity-100" : "opacity-0"
          }`}
        >
          <img src={Logo} alt="Logo" className="h-16" />
        </div>
        <button
          onClick={toggleMenu}
          className="md:hidden text-white flex focus:outline-none right-4 absolute"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>

        <div
          className={`md:flex gap-5 transition-opacity data-op duration-1000 ${
            showButtons ? "opacity-100" : "opacity-0"
          }`}
        >
          {initialNavigation.map((item, index) => (
            <div
              key={index}
              className={`relative hidden md:block text-white`}
              style={{
                animation: showButtons
                  ? `fade-in-from-top 1s ease-in-out ${index * 0.3}s forwards`
                  : "none",
              }}
            >
              {item.children ? (
                <div className={`group cursor-pointer relative `}>
                  {item.name}
                  <div
                    className={`absolute space-y-2 md:hidden group-hover:block bg-[#0000008c] text-white shadow-lg p-2  `}
                  >
                    {item.children.map((child, childIndex) => (
                      <Link
                        key={childIndex}
                        to={child.href}
                        className={`block py-1 px-2 min-w-max text-white hover:bg-gray-700 ${
                          showChildnav === childIndex
                            ? "opacity-100"
                            : "opacity-0"
                        } `}
                        style={{
                          animation: showChildnav
                            ? `fade-in-from-top 1s ease-in-out ${
                                childIndex * 0.3
                              }s forwards`
                            : "none",
                        }}
                      >
                        {child.name}
                      </Link>
                    ))}
                  </div>
                </div>
              ) : (
                item.href.startsWith('/') ? (
                  <Link to={item.href} className="hover:underline">
                    {item.name}
                  </Link>
                ) : (
                  <button onClick={() => handleScroll(item.href)} className="hover:underline">
                    {item.name}
                  </button>
                )
              
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Mobile Navigation Links */}
      <div
        className={`md:hidden  ${
          isMenuOpen ? "block" : "hidden"
        }  transition-opacity duration-1000  ${
          showButtons ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className={`flex flex-col   `}>
          {initialNavigation.map((item, index) => (
            <div key={index} className="relative">
              {item.children ? (
                <>
                  <button
                    className={`w-full text-left group cursor-pointer py-2 px-4 rounded-md hover:bg-gray-600 ${
                      showButtons ? "opacity-100" : "opacity-0"
                    }`}
                    onClick={() => toggleDropdown(index)}
                  >
                    {item.name}
                  </button>
                  <div
                    className={`pl-4 mt-2  space-y-2 rounded-md ${
                      dropdownOpen === index ? "block" : "hidden"
                    }`}
                  >
                    {item.children.map((child, childIndex) => (
                      <Link
                        key={childIndex}
                        to={child.href}
                        className={`block py-2 px-4 hover:bg-gray-600 ${
                          showChildnav === childIndex
                            ? "opacity-100"
                            : "opacity-0"
                        } `}
                        style={{
                          animation: showChildnav
                            ? `fade-in-from-top 1s ease-in-out ${
                                childIndex * 0.3
                              }s forwards`
                            : "none",
                        }}
                      >
                        {child.name}
                      </Link>
                    ))}
                  </div>
                </>
              ) : (
                item.href.startsWith('/') ? (
                  <Link
                    key={index}
                    to={item.href}
                    className={`block py-2 px-4 hover:bg-gray-600 ${showButtons ? 'opacity-100' : 'opacity-0'}`}
                  >
                    {item.name}
                  </Link>
                ) : (
                  <button
                    key={index}
                    onClick={() => handleScroll(item.href)}
                    className={`block py-2 px-4 hover:bg-gray-600 ${showButtons ? 'opacity-100' : 'opacity-0'}`}
                  >
                    {item.name}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
