import React, { useState, useEffect, useRef } from "react";
import Circleimg from "../Assets/Images/Protractor V3-01 (1) (2).png";
import Circleline from "../Assets/Images/output-onlinepngtools.png";
import Dhinesh from "../Assets/TeamImages/Dhinesh.png";
import Santosh from "../Assets/TeamImages/Santosh.png";
import Anil from "../Assets/TeamImages/Anil.png";
import Danny from "../Assets/TeamImages/Danny.png";
import Pradeep from "../Assets/TeamImages/Pradeep.png";
import Praveen from "../Assets/TeamImages/Praveen.png";
import Mahantesha from "../Assets/TeamImages/Mahantesha.png";
import Manoj from "../Assets/TeamImages/Manoj.png";
import Rohith from "../Assets/TeamImages/Rohith.png";
import "./Ourarchitecture.css";
import bgImage from "../Assets/Images/pulsebg6.png"
const profiles = [
  {
    id: 1,
    name: "Dhinesh N",
    designation: "Digital Head",
    quote:
      "Empower growth and embody excellence – be the heartbeat of the brand's success.",
    image: Dhinesh,
  },
  {
    id: 2,
    name: "Pradeep Kumar S",
    designation: "Assistant Manager - Pre Sales",
    quote: "I'm a self-propelled force.",
    image: Pradeep,
  },
  {
    id: 3,
    name: "Anil Nakode",
    designation: "Manager - Pre Sales",
    quote: "Forge your own path and create the opportunities you seek.",
    image: Anil,
  },
  {
    id: 4,
    name: "Praveen Sekhar",
    designation: "Sales Regional Head",
    quote: "Success demands hard work.",
    image: Praveen,
  },
  {
    id: 5,
    name: "Mahantesha T R",
    designation: "Sales Manager",
    quote: "Discipline drives achievement.",
    image: Mahantesha,
  },
  {
    id: 6,
    name: "Manoj Kumar Sahoo",
    designation: "Sales Manager",
    quote: "Quality shows when no one’s watching.",
    image: Manoj,
  },
  {
    id: 7,
    name: "Santosh Rajan",
    designation: "Sales Regional Head",
    quote: "Nothing succeeds without our effort.",
    image: Santosh,
  },
  {
    id: 8,
    name: "Sandeep Daniel S",
    designation: "Senior - Business Development Manager",
    quote: "Our goal centers on vision, speed, and delivering value.",
    image: Danny,
  },
  {
    id: 9,
    name: "Rohith Dwarakha",
    designation: "Creative Head",
    quote: "Lead the way; don't follow the crowd.",
    image: Rohith,
  },
];

const animationConfig = {
  enterDelay: 500,
  rotateDelay: 500,
  leaveDelay: 500,
  transitionDuration: 500,
  textdelay: 2000,
};

const Ourarchitecture = () => {
  const [rotated, setRotated] = useState(false);
  const [lineVisible, setLineVisible] = useState(true);
  const [profileVisible, setProfileVisible] = useState(true);
  const [imageTransition, setImageTransition] = useState(true);
  const [currentProfileIndex, setCurrentProfileIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");
  const [showPluse, setShowPluse] = useState(false);
  const circleRef = useRef(null);
  const circleLineRef = useRef(null);
  const circleRefMob = useRef(null);
  const circleLineRefMob = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPluse(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      handleMouseEnter();
    }, 9000); 
    return () => clearInterval(interval);
  }, []);
  
  const handleMouseEnter = () => {
    setProfileVisible(false);
    setTimeout(() => setLineVisible(false), animationConfig.enterDelay);
    setTimeout(() => {
      setRotated(true);
      setImageTransition(true);

      // Circle image rotation
      const rotation = circleRef.current.dataset.rotation
        ? parseFloat(circleRef.current.dataset.rotation) + 40
        : 40;

      circleRef.current.style.transition = "transform 2s ease-in-out";
      circleRef.current.style.transform = `rotate(${rotation}deg)`;
      circleRef.current.dataset.rotation = rotation;

      // Circle line rotation
      const lineRotation = circleLineRef.current.dataset.rotation
        ? parseFloat(circleLineRef.current.dataset.rotation) + 45
        : 45;

      circleLineRef.current.style.transition = "transform 4s linear";
      circleLineRef.current.style.transform = `rotate(${lineRotation}deg)`;
      circleLineRef.current.dataset.rotation = lineRotation;

    }, animationConfig.rotateDelay);
  };
  const handleMouseEnterMobile = () => {
    setProfileVisible(false);
    setTimeout(() => setLineVisible(false), animationConfig.enterDelay);
    setTimeout(() => {
      setRotated(true);
      setImageTransition(true);
  
      // Circle image rotation for mobile
      const rotationMob = circleRefMob.current.dataset.rotation
        ? parseFloat(circleRefMob.current.dataset.rotation) + 20
        : 20;
  
      circleRefMob.current.style.transition = 'transform 1s ease-in-out';
      circleRefMob.current.style.transform = `rotate(${rotationMob}deg)`;
      circleRefMob.current.dataset.rotation = rotationMob;
  
      // Circle line rotation for mobile
      const lineRotationMob = circleLineRefMob.current.dataset.rotation
        ? parseFloat(circleLineRefMob.current.dataset.rotation) + 30
        : 30;
  
      circleLineRefMob.current.style.transition = 'transform 2s linear';
      circleLineRefMob.current.style.transform = `rotate(${lineRotationMob}deg)`;
      circleLineRefMob.current.dataset.rotation = lineRotationMob;
  
    }, animationConfig.rotateDelay);
  };
  const handleMouseLeave = () => {
    setTimeout(() => {
      setRotated(false);
      setLineVisible(true);
      setCurrentProfileIndex((prevIndex) => (prevIndex + 1) % profiles.length);
      setTimeout(() => setProfileVisible(true), animationConfig.textdelay);
      // setProfileVisible(true);
    }, animationConfig.leaveDelay);
  };

  const handleClick = () => {
    setRotated(false);
    setLineVisible(true);
    setCurrentProfileIndex((prevIndex) => (prevIndex + 1) % profiles.length);
    setTimeout(() => setProfileVisible(true), animationConfig.textdelay);
    setImageTransition(true);
  
    // Circle image rotation for mobile
    const rotationMob = circleRefMob.current.dataset.rotation
      ? parseFloat(circleRefMob.current.dataset.rotation) + 40
      : 40;
  
    circleRefMob.current.style.transition = 'transform 1s ease-in-out';
    circleRefMob.current.style.transform = `rotate(${rotationMob}deg)`;
    circleRefMob.current.dataset.rotation = rotationMob;
  
    // Circle line rotation for mobile
    const lineRotationMob = circleLineRefMob.current.dataset.rotation
      ? parseFloat(circleLineRefMob.current.dataset.rotation) + 30
      : 30;
  
    circleLineRefMob.current.style.transition = 'transform 2s linear';
    circleLineRefMob.current.style.transform = `rotate(${lineRotationMob}deg)`;
    circleLineRefMob.current.dataset.rotation = lineRotationMob;
  };

  useEffect(() => {
    let timer;
    if (rotated) {
      timer = setTimeout(() => {
        setRotated(false);
        setLineVisible(true);
        setCurrentProfileIndex(
          (prevIndex) => (prevIndex + 1) % profiles.length
        );
        setTimeout(() => setProfileVisible(true), animationConfig.textdelay);
        setImageTransition(true);
      }, animationConfig.transitionDuration);
    }
    return () => clearTimeout(timer);
  }, [rotated]);

  useEffect(() => {
    setAnimationClass("fade-in-right");
    const timer = setTimeout(() => {
      setAnimationClass("");
    }, animationConfig.enterDelay);
    return () => clearTimeout(timer);
  }, [currentProfileIndex]);

  return (
    <div className="relative h-auto items-center justify-start -mb-6 " style={{
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: 'no-repeat',
    }}>
      {/* <p className="text-yellow-600 text-2xl font-semibold md:ml-0 ml-2 mt-2">Pulse</p> */}
      <div className="relative text-[#DDA556] bg-black -mb-2 text-3xl font-mono z-30 flex flex-row px-9 mt-4">
        {showPluse && ["P", "u", "l", "s", "e"].map((letter, index) => (
          <div
            key={index}
            className="letter-animation"
            style={{ animationDelay: `${(4 - index) * 100}ms` }}
          >
            <h2>{letter}</h2>
          </div>
        ))}
      </div>
      <div>
        <div className={`items-center ${rotated ? "rotated" : ""}`}>
          <div className="grid grid-cols-1 md:grid-cols-3 lg: relative " >
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="relative w-[100%] h-[100%] lg:w-[500px] md:w-[428px] lg:h-[500px] lg:ml-[-12rem] md:ml-[-165px] md:mt-0 lg:-mt-4 hidden md:block"
            >
              <img
                ref={circleRef}
                src={Circleimg}
                alt="Circle"
                className="absolute inset-0 m-auto w-[250px] md:w-[200px] lg:w-[410px] h-[250px] md:h-[200px] lg:h-[400px] transition-transform duration-300 ease-in-out z-10 "
              />
              <img
                ref={circleLineRef}
                src={Circleline}
                alt="Line"
                className="absolute inset-0 m-auto w-[290px] md:w-[235px] lg:w-[485px] h-[290px] md:h-[235px] lg:h-[475px] transition-transform duration-300 ease-in-out z-0 "
              />
              <div className="absolute inset-0 bg-black  w-1/2 h-full z-20"></div>
            </div>

            <div className="hidden md:block">
              {lineVisible && (
                <div className="progress-line h-full top-1/3 md:top-2/4 lg:top-[49%] lg:-ml-12 xl:-ml-40  md:-ml-20 ml-16   w-1 bg-gray-500"></div>
              )}
              {profileVisible && (
                <div
                  className={`fade-in-right  md:mt-0 lg:mt-32 ml-10 md:-ml-16 lg:-ml-3 xl:-ml-48  text-start  z-10 ${animationClass}`}
                >
                  <p className="quote text-center  text-[18px] text-[#7a7a7a] font-bold">
                    {profiles[currentProfileIndex].quote}
                  </p>
                  <p className=" text-end  text-[16px] text-[#7a7a7a]  text-sm">
                    <span className="name text-xl">{profiles[currentProfileIndex].name},</span>  {profiles[currentProfileIndex].designation}
                  </p>
                  {/* <p className="designation text-end  text-[14px] text-[#7a7a7a]">
                    {profiles[currentProfileIndex].designation}
                  </p> */}
                </div>
              )}
            </div>
            <div className="relative flex justify-center items-center">
              <div className="image-container hidden md:block mb-12 mt-9 lg:mt-8 w-[200px] lg:[300px] xl:w-[400px] md:w-[200px] h-[200px] md:h-[200px] lg:h-[300px] xl:h-[400px]">
                {profiles.map((profile, index) => (
                  <img
                    key={profile.id}
                    src={profile.image}
                    alt={profile.name}
                    className={`w-[200px] lg:w-[300px] xl:w-[350px] md:w-[200px] h-[200px] md:h-[200px] lg:h-[300px] xl:h-[354px] ${imageTransition && index === currentProfileIndex
                      ? "active"
                      : "inactive"
                      }`}

                  />
                ))}
              </div>
            </div>
          </div>
          <div className="grid grid-rows-3  md:hidden lg:hidden">
            <div
              onMouseEnter={handleMouseEnterMobile}
              onMouseLeave={handleMouseLeave}
              className="relative top-[-100px] w-[100%] h-[100%] lg:w-[500px]  md:w-[428px]   lg:h-[500px] lg:ml-[-12rem] md:ml-[-165px] md:mt-0  lg:-mt-4"
            >
              <img
              onClick={handleClick}
                ref={circleRefMob}
                src={Circleimg}
                alt="Circle"
                className="absolute inset-0 m-auto w-[250px] md:w-[200px] lg:w-[410px] h-[250px] md:h-[200px] lg:h-[400px] transition-transform duration-300 ease-in-out z-10"
              />
              <img
              onClick={handleClick}
                ref={circleLineRefMob}
                src={Circleline}
                alt="Line"
                className="absolute inset-0 m-auto w-[290px] md:w-[235px] lg:w-[485px] h-[290px] md:h-[235px] lg:h-[475px] transition-transform duration-300 ease-in-out z-0 "
              />
              <div className="absolute top-0  bg-black  w-full h-1/2  z-20"></div>
            </div>
            <div>
              {lineVisible && (
                <div className="progress-line h-full top-1/3 md:top-2/4 lg:top-1/2 lg:-ml-12 xl:-ml-48   md:-ml-20 ml-16   w-1 bg-gray-500"></div>
              )}
              {profileVisible && (
                <div
                  className={`fade-in-right  relative bottom-14  md:mt-0 lg:mt-32 ml-8 mr-2 md:-ml-16 lg:-ml-3 xl:-ml-48  text-start z-10 ${animationClass}`}
                >
                  <p className="quote text-center  text-[16px] text-[#7a7a7a]">
                    {profiles[currentProfileIndex].quote}
                  </p>
                  <p className="name text-center  text-[20px]">
                    {profiles[currentProfileIndex].name}
                  </p>
                  <p className="designation text-center  text-[16px] text-[#7a7a7a]">
                    {profiles[currentProfileIndex].designation}
                  </p>
                </div>
              )}
            </div>
            <div className="relative flex justify-center  bottom-[121px]">
              <div className="image-container mb-12  lg:mt-8 w-[200px] lg:[300px] xl:w-[400px] md:w-[200px] h-[200px] md:h-[200px] lg:h-[300px] xl:h-[350px]">
                {profiles.map((profile, index) => (
                  <img
                    key={profile.id}
                    src={profile.image}
                    alt={profile.name}
                    className={`w-[200px] lg:w-[300px] xl:w-[400px] md:w-[200px] h-[200px] md:h-[200px] lg:h-[300px] xl:h-[350px] ${imageTransition && index === currentProfileIndex
                      ? "active"
                      : "inactive"
                      }`}

                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourarchitecture;
