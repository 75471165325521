import React, { useEffect,useState ,useRef} from "react";
import img1 from "../Assets/Images/Rectangle 68-1.png";
import img2 from "../Assets/Images/Rectangle 68.png";
import img3 from "../Assets/Images/Rectangle 69-1.png";
import img4 from "../Assets/Images/Rectangle 69.png";

import "../App.css";
import bgImage from "../Assets/Images/pulsebg6.png"
const VibeCheck = () => {
  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".rotate-on-scroll");
      if (element) {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isVisible) {
          element.classList.add("rotate-on-scroll");
        } else {
          element.classList.remove("rotate-on-scroll");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const aosInitialized = useRef(false);

  return (
    <div className="container-fluid px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
      <div className="flex flex-col justify-center p-4 md:ml-10  md:text-center"  style={{
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    
  }}>
          <div className="flex flex-row  md:justify-center md:text-center">
            <div
              data-aos="new-animation"
              data-aos-delay="2000"
              data-aos-easing="ease-in-out"
            >
              <h2 className="text-3xl font-medium text-[#DDA556]">Vibe</h2>
            </div>
            <div
              data-aos="new-animation"
              data-aos-delay="2200"
              data-aos-easing="ease-in-out"
            >
              <h2 className="text-3xl font-medium text-[#DDA556] ml-2">
                Check
              </h2>
            </div>
          </div>
          <div
            data-aos="new-animate"
            data-aos-delay="2400"
            data-aos-easing="ease-in-out"
          >
            <p className="mt-2 text-[16px] text-[#7a7a7a]">
              We fuse expertise with an engineering, dynamic workplace.
            </p>
          </div>
          <div
            data-aos="new-animate"
            data-aos-delay="2600"
            data-aos-easing="ease-in-out"
          >
            <p className="mt-[-2px] text-[16px] text-[#7a7a7a]">
              <span className="font-bold">
                Collaboration, innovation, and excellence fuel us.
              </span>
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
          <div
            data-aos="new-right"
            data-aos-delay="1400"
            data-aos-easing="ease-in-out"
          >
            <div className="card group relative overflow-hidden">
              <img
                src={img1}
                alt="Placeholder 1"
                className="h-44 w-auto md:h-56 lg:h-52 object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                style={{ width: "-webkit-fill-available" }}
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-center opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
                <p className="text-lg font-bold">EMPOWER</p>
              </div>
            </div>
          </div>
          <div
            data-aos="new-right"
            data-aos-delay="1200"
            data-aos-easing="ease-in-out"
          >
            <div className="card group relative overflow-hidden">
              <img
                src={img2}
                alt="Placeholder 1"
                className="h-44 md:h-56 lg:h-52 object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                style={{ width: "-webkit-fill-available" }}
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-center opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
                <p className="text-lg font-bold">INNOVATE</p>
              </div>
            </div>
          </div>
          <div
            data-aos="new-right"
            data-aos-delay="1600"
            data-aos-easing="ease-in-out"
          >
            <div className="card group relative overflow-hidden">
              <img
                src={img3}
                alt="Placeholder 1"
                className="h-44 md:h-56 lg:h-52 object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                style={{ width: "-webkit-fill-available" }}
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-center opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
                <p className="text-lg font-bold">CUSTOMER - CENTRIC</p>
              </div>
            </div>
          </div>
          <div
            data-aos="new-right"
            data-aos-delay="1800"
            data-aos-easing="ease-in-out"
          >
            <div className="card group relative overflow-hidden">
              <img
                src={img4}
                alt="Placeholder 1"
                className="h-44 md:h-56 lg:h-52 object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                style={{ width: "-webkit-fill-available" }}
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-white text-center opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
                <p className="text-lg font-bold">TECH SAVY</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VibeCheck;
