import React, { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { IoIosCloseCircle } from "react-icons/io";
import { IoCloudUploadOutline } from "react-icons/io5";
import { AiOutlineMail, AiOutlineDown } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import axios from "axios";
import Swal from "sweetalert2";
const Modalform = ({ isModalOpen, handleCloseModal }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: "Sales", label: "Sales executive" },
    { value: "Presales", label: "Presales executive" },
    { value: "Web Development", label: "Web Development" },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    applyingFor: "",
    resume: null,
  });
  const [errorname, setErrorname] = useState(false);
  const [erroremail, setErroremail] = useState(false);
  const [errorphone, setErrorphone] = useState(false);
  const [errorapplyingFor, setErrorapplyingFor] = useState(false);
  const [errorcv, setErrorcv] = useState(false);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case "name":
        setErrorname(false);
        break;
      case "email":
        setErroremail(false);
        break;
      case "phone":
        setErrorphone(false);
        break;
      case "applyingFor":
        setErrorapplyingFor(false);
      default:
        break;
    }
  };
  const [selectedFileName, setSelectedFileName] = useState('');
  const handleFileChange = (e) => {
    // setFormData({
    //   ...formData,
    //   resume: e.target.files[0],
    // });
    const file = e.target.files[0];
    setFormData({ ...formData, resume: file });
    setSelectedFileName(file.name); // Set the selected file name
    // setErrors({ ...errors, resume: false });

    if (formData.resume) {
      setErrorcv(false);
    }
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    const { name, email, phone, applyingFor, resume } = formData;
    // if (!name || !email || !phone || !applyingFor || !cv) {
    //   alert('All fields are required');
    //   return;
    // }

    if (!name) {
      setErrorname(true);
      return;
    }
    if (!email) {
      setErroremail(true);
      return;
    }
    if (!phone) {
      setErrorphone(true);
      return;
    }
    if (!applyingFor) {
      setErrorapplyingFor(true);
      return;
    }
    if (!resume) {
      setErrorcv(true);
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('jobRole', formData.applyingFor);
    formDataToSend.append('resume', formData.resume);
    try {
     
      const response = await axios.post('https://leadapi.homebble.in/formdataRoute/sendMailJoinSquad', formDataToSend);
      Swal.fire('Success', response.data.message, 'success');
      setSelectedFileName("")
      handleCloseModal(); 
      setFormData({
        name: "",
        email: "",
        phone: "",
        applyingFor: "",
        resume: null,
      });
  
    } catch (error) {
  
      console.error('Error submitting form:', error);
      Swal.fire('Error', 'An error occurred while submitting the form', 'error');
    }

  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleCloseModal}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm"
        aria-hidden="true"
      ></div>
      <DialogPanel
        className={`relative p-4 mx-auto mt-20 max-w-xl w-full bg-black bg-opacity-50 rounded-lg shadow-xl transition-transform transform ${
          isModalOpen ? "animate-scale-up opacity-100" : "opacity-0"
        }`}
      >
        <div className="text-start">
          <DialogTitle
            as="h3"
            className="mt-4 text-lg font-semibold text-[#DDA556] text-center"
          >
            Join the Squad
          </DialogTitle>
          <button
            onClick={handleCloseModal}
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-200"
          >
            <IoIosCloseCircle size={28} className="text-[#dda556]" />
          </button>
        </div>
        <div className="text-black p-8 max-w-xl w-full">
          <form className="space-y-4" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
              className={`w-full p-3 bg-gray-700 bg-opacity-50 text-white border-[1px] ${
                errorname ? "border-red-800" : "border-[#dda556]"
              }  focus:outline-none focus:ring-2 focus:ring-[#dda556]`}
            />
            {errorname && (
              <p className="bg-red-800 rounded-md flex items-center text-white p-2">
                <FiAlertCircle className="text-white mr-2 text-lg" />
                Name Can't be Empty
              </p>
            )}
            <div className="relative">
              <input
                type="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full p-3 bg-gray-700 bg-opacity-50 text-white border-[1px] ${
                  erroremail ? "border-red-800" : "border-[#dda556]"
                }   focus:outline-none focus:ring-2 focus:ring-[#dda556]`}
              />
              <AiOutlineMail className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#dda556] h-5 w-6" />
            </div>
            {erroremail && (
              <p className="bg-red-800 rounded-md flex items-center text-white p-2">
                <FiAlertCircle className="text-white mr-2 text-lg" />
                Email Can't be Empty
              </p>
            )}
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleChange}
              className={`w-full p-3 bg-gray-700 bg-opacity-50 text-white border-[1px] ${
                errorphone ? "border-red-800" : "border-[#dda556]"
              }  focus:outline-none focus:ring-2 focus:ring-[#dda556]`}
            />
            {errorphone && (
              <p className="bg-red-800 rounded-md flex items-center text-white p-2">
                <FiAlertCircle className="text-white mr-2 text-lg" />
                Phone Can't be Empty
              </p>
            )}
            <div className="relative">
              <div
                className={`flex items-center justify-between w-full p-3 bg-gray-700 bg-opacity-50 text-gray-400 border-[1px] ${
                  errorapplyingFor ? "border-red-800" : "border-[#dda556]"
                }  focus:outline-none focus:ring-2 focus:ring-[#dda556] cursor-pointer`}
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <span>
                  {selectedOption
                    ? selectedOption.label
                    : "Select applying for"}
                </span>
                <AiOutlineDown className="text-[#dda556] h-5 w-6" />
              </div>
              {showDropdown && (
                <div
                  className={`absolute z-10 mt-1 w-full bg-black bg-opacity-80 border-[1px] ${
                    errorapplyingFor ? "border-red-800" : "border-[#dda556]"
                  }  text-white`}
                >
                  {options.map((option, index) => (
                    <div
                      key={index}
                      className="p-3 cursor-pointer hover:bg-gray-600"
                      onClick={() => {
                        setSelectedOption(option);
                        setShowDropdown(false);
                        setErrorapplyingFor(false);
                        setFormData({ ...formData, applyingFor: option.value });
                      }}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {errorapplyingFor && (
              <p className="bg-red-800 rounded-md flex items-center text-white p-2">
                <FiAlertCircle className="text-white mr-2 text-lg" />
                ApplyingFor Can't be Empty
              </p>
            )}
            <div className="relative w-full">
              <input
                type="file"
                name="cv"
                onChange={handleFileChange}
                className="sr-only"
                id="cv-upload"
              
              />
              <label
                htmlFor="cv-upload"
                className={`relative flex items-center justify-between w-full p-3 bg-gray-700 bg-opacity-50 text-gray-400 border-[1px] ${
                  errorcv ? "border-red-800" : "border-[#dda556]"
                } focus:outline-none focus:ring-2 focus:ring-[#dda556] cursor-pointer`}
              >
               <span>{selectedFileName ? selectedFileName : "Upload CV"}</span>
                <IoCloudUploadOutline className="text-[#dda556] h-5 w-6" />
              </label>
            </div>
            {errorcv && (
              <p className="bg-red-800 rounded-md flex items-center text-white p-2">
                <FiAlertCircle className="text-white mr-2 text-lg" />
                CV Can't be Empty
              </p>
            )}
            <div className="flex justify-center">
              <button
                type="submit"
                className="flex items-center justify-center w-2/4 mt-3 p-3 bg-[#dda556] hover:bg-yellow-700 text-black font-semibold focus:outline-none focus:ring-2 focus:ring-[#dda556]"
              >
                <span className="mr-6">Submit</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="[#dda556]"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 01.5-.5h11.793l-3.147-3.146a.5.5 0 01.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L13.293 8.5H1.5A.5.5 0 011 8z"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default Modalform;
