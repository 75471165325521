import React, { useEffect, useState } from 'react';
import bgImage1 from '../Assets/Images/PNG3.png';
import bgImage2 from '../Assets/Images/Rectangle-76.png';
import { IoArrowForwardCircleOutline, IoArrowBackCircleOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

function Portfolio() {
    const [showFirstImage, setShowFirstImage] = useState(true);

    useEffect(() => {
      
      
        // Set up the interval to switch images and content every 6 seconds
        const interval = setInterval(() => {
            setShowFirstImage((prev) => !prev);
        }, 7000);

        // Clear the interval if the component unmounts
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="container-fluid px-4 lg:h-screen bg-black flex flex-col lg:pb-16 justify-between">
            <div className="relative h-[22rem] md:h-[28rem] text-white py-10 rounded-xl overflow-hidden">
                <div
                    className={`absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ease-in-out zoom-inp ${showFirstImage ? 'opacity-100' : 'opacity-0'}`}
                    style={{ backgroundImage: `url(${bgImage1})` }}
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="z-10 absolute bottom-0 sm:bottom-5 sm:left-5 p-5 rounded flex flex-col sm:flex-row items-center">
                        <div className="w-full sm:w-9/12">
                            <div className="flex flex-wrap space-x-1.5 ">
                                <div data-aos="new-animation" data-aos-delay="950" data-aos-easing="ease-in-out">
                                    <p className="text-xl sm:text-3xl text-[#DDA556]">Portfolio </p>
                                </div>
                                <div data-aos="new-animation" data-aos-delay="1050" data-aos-easing="ease-in-out">
                                    <p className="text-xl sm:text-3xl text-[#DDA556] "> management </p>
                                </div>
                                <div data-aos="new-animation" data-aos-delay="1150" data-aos-easing="ease-in-out">
                                    <p className="text-xl sm:text-3xl text-[#DDA556] "> investment</p>
                                </div>
                                <div data-aos="new-animation" data-aos-delay="1250" data-aos-easing="ease-in-out">
                                    <p className="text-xl sm:text-3xl text-[#DDA556] "> advice</p>
                                </div>
                            </div>
                            <div data-aos="new-animation" data-aos-delay="1450" data-aos-easing="ease-in-out">
                                <p className="mt-4 text-[#7a7a7a] md:text-[16px] text-[14px] text-justify">
                                    Maximize returns with Mark Anthony Real Estate. Our experts offer tailored portfolio management and strategic investment advice. Partner with us for personalized guidance and comprehensive support.
                                </p>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div
                    className={`absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ease-in-out zoom-inp ${showFirstImage ? 'opacity-0' : 'opacity-100'}`}
                    style={{ backgroundImage: `url(${bgImage2})` }}
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="z-10 absolute bottom-0 sm:bottom-5 sm:left-5 p-5 rounded flex flex-col sm:flex-row items-center">
                        <div className="w-full sm:w-9/12">
                            <div data-aos="new-animation" data-aos-delay="150" data-aos-easing="ease-in-out">
                                <h2 className="text-xl sm:text-3xl text-[#DDA556]">Portfolio planning</h2>
                            </div>
                            <div data-aos="new-animation" data-aos-delay="350" data-aos-easing="ease-in-out">
                                <p className="mt-4 text-[#7a7a7a] md:text-[16px] text-[14px] text-justify">
                                    Optimize your investments with Mark Anthony Real Estate. Our expert team provides tailored portfolio planning to help you achieve your financial goals. Trust us for strategic guidance and personalized support.
                                </p>
                            </div>
                        </div>
                        <div className="md:hidden flex justify-center sm:justify-end mt-4 sm:mt-0 w-full sm:w-3/12 sm:px-10">
                            <IoArrowBackCircleOutline className="text-3xl sm:text-4xl text-yellow-600 cursor-pointer" />
                            <IoArrowForwardCircleOutline className="text-3xl sm:text-4xl text-yellow-600 cursor-pointer ml-4" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col hidden lg:block md:flex-col bg-black px-4">
        <div
          className="order-2 md:order-1 text-center md:mt-5 py-5"
          data-aos="new-up"
          data-aos-delay="2500"
          data-aos-easing="ease-in-out"
        >
          <h3
            className="text-lg md:text-2xl text-[#ecd0a8] md:tracking-[0.8em] tracking-[0.4em]"
            style={{ fontFamily: "Bodoni MT, serif" }}
          >
            MARKANTHONY
          </h3>
        </div>
        <Link to="/" className="order-1 md:order-2">
          <div
            className="flex justify-end lg:justify-start md:justify-start"
            data-aos="new-up"
            data-aos-delay="3000"
            data-aos-easing="ease-in-out"
          >
            <button className="group bottom-5 md:left-5 text-3xl text-[#DDA556] font-light py-2 rounded relative md:mt-2 mt-10 flex justify-end">
              <span className="inline-block">
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip">
                  B
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-100">
                  a
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-200">
                  c
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-300">
                  k
                </span>
              </span>
            </button>
          </div>
        </Link>
      </div>
      <div className="flex flex-col block lg:hidden md:flex-col bg-black px-4">
        <div
          className="order-2 md:order-1 text-center md:mt-5 py-5"
   
        >
          <h3
            className="text-lg md:text-2xl text-[#ecd0a8] md:tracking-[0.8em] tracking-[0.4em]"
            style={{ fontFamily: "Bodoni MT, serif" }}
          >
            MARKANTHONY
          </h3>
        </div>
        <Link to="/" className="order-1 md:order-2">
          <div
            className="flex justify-end lg:justify-start md:justify-start"
            data-aos="new-up"
            data-aos-delay="3000"
            data-aos-easing="ease-in-out"
          >
            <button className="group bottom-5 md:left-5 text-3xl text-[#DDA556] font-light py-2 rounded relative md:mt-2 mt-10 flex justify-end">
              <span className="inline-block">
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip">
                  B
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-100">
                  a
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-200">
                  c
                </span>
                <span className="transform scale-x-[-1] inline-block group-hover:animate-flip delay-300">
                  k
                </span>
              </span>
            </button>
          </div>
        </Link>
      </div>
        </div>
    );
}

export default Portfolio;
