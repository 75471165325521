import React, { useEffect, useState } from 'react';
import Video from "../Assets/Videos/Markanthony-intro-compressed.mp4";
import './home.css'; 

const Banner = () => {
  const [showContent, setShowContent] = useState(false);
const [showvideo, setShowvideo] = useState(false)

useEffect(() => {
  const timer1 = setTimeout(() => setShowvideo(true), 100);

  return () => {
    clearTimeout(timer1);
  };
}, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 8000); 

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative">
      <video
        className= {`transition-opacity duration-100 w-full object-fill lg:h-screen lg:mt-[-169px] mt-[-30px] md:mt-[-169px] ${showvideo ? 'opacity-100' : 'opacity-0'}`}
        autoPlay
        muted
        loop
      >
        <source src={Video} type="video/mp4" />
      </video>
      <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-transparent flex justify-center items-center">
        <h2 className="text-white lg:text-3xl md:text-3xl text-2xl">
          <div className={`flex flex-row ${showContent ? '' : 'hidden'}`}>
            <div className='flex flex-row tracking-widest font-extralight'>
              <div className='ml-2 text-animation' style={{ animationDelay: '0s' }}>
                We
              </div>
              <div className='ml-2 text-animation' style={{ animationDelay: '.3s' }}>
                are
              </div>
              <div className='ml-2 text-animation' style={{ animationDelay: '.9s' }}>
                the
              </div>
            </div>
            <div className='difference-animation' style={{ animationDelay: '1.2s' }}>
              <span className="text-transparent lg:text-3xl md:text-3xl text-2xl bg-clip-text bg-gradient-to-r from-[#B88A44] via-[#DEBD68] to-[#DBB658] ml-2 font-extrabold tracking-widest">
                difference
              </span>
            </div>
          </div>
        </h2>
      </div>
    </div>
  );
};

export default Banner;
