import React, { useState, useRef } from 'react';
import Video from "./Assets/Videos/OpeningSlide_Final.mp4";
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import './Loading.css'; // Import the CSS file for the animation

const Loading = ({ onVideoEnd }) => {
  const [isMuted, setIsMuted] = useState(true);
  const [isZoomed, setIsZoomed] = useState(false); // State to control zoom effect
  const videoRef = useRef(null); // Reference to the video element

  const toggleMute = (e) => {
    e.stopPropagation(); 
    setIsMuted((prevMuted) => !prevMuted);
  };

  const handleVideoEnd = () => {
    setIsZoomed(true); // Trigger the zoom effect
    // Set a timeout to call onVideoEnd after 1.5 seconds
    setTimeout(() => {
      onVideoEnd(); // Call the onVideoEnd function
    }, 1500); // 1.5 seconds delay after video ends
  };

  return (
    <div 
      className="w-full h-screen relative" 
      onClick={toggleMute} 
    >
      <video
        ref={videoRef}
        className={`w-full h-full object-cover ${isZoomed ? 'zoom-animations' : ''}`}
        autoPlay
        muted={isMuted}
        onEnded={handleVideoEnd}
      >
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <button
        onClick={(e) => {
          e.stopPropagation(); 
          toggleMute(e);
        }}
        className="absolute bottom-5 left-5 bg-transparent bg-opacity-50 p-2 rounded-full shadow-md hover:bg-gray-800"
      >
        {isMuted ? (
          <FaVolumeMute size={24} style={{ color: '#ead1a9' }} />
        ) : (
          <FaVolumeUp size={24} style={{ color: '#ead1a9' }} />
        )}
      </button> */}
    </div>
  );
};

export default Loading;
