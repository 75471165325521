import React ,{useState}from 'react';
import img4 from "../Assets/Images/Join the squad.png";
import "../App.css";
import Modalform from "./Modalform"
const Jointheteam = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="container-fluid px-4">
    <div id="JoinTheSquad">
      <div className="  mb-[-20px] z-10 relative">
        <div data-aos="fade-top" data-aos-delay="150" data-aos-easing="ease-in-out">
          <img
            src={img4}
            alt="New Image"
            className="w-full h-[32rem] object-cover  bg-black opacity-50 zoom-out"
          />
        </div>

        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black via-transparent to-transparent"></div>
        <div className="absolute bottom-0 left-0 p-4 mb-8 ml-2 md:w-[36rem]">
          <div className='flex flex-row'>
            <div data-aos="new-animation" data-aos-delay="250" data-aos-easing="ease-in-out">
              <h2 className="text-3xl font-medium text-[#DDA556] ">Join</h2>
            </div>
            <div data-aos="new-animation" data-aos-delay="450" data-aos-easing="ease-in-out">
              <h2 className="text-3xl font-medium text-[#DDA556] ml-2">the</h2>
            </div>
            <div data-aos="new-animation" data-aos-delay="650" data-aos-easing="ease-in-out">
              <h2 className="text-3xl font-medium text-[#DDA556] ml-2">Squad</h2>
            </div>
          </div>
          <div data-aos="new-animate" data-aos-delay="1050" data-aos-easing="ease-in-out">
            <p className="mt-2 text-[16px] text-[#7a7a7a]">
              Confident in your skills? Ready to redefine real estate excellence?
              Show us what you've got and join the Mark Anthony Team.
            </p>
          </div>
          <div data-aos="new-animate" data-aos-delay="1350" data-aos-easing="ease-in-out">
            <p className="mt-1 text-[16px] text-[#7a7a7a]">
              <span className="font-bold">
                Let's see if you're up for the challenge!
              </span>
            </p>
          </div>
          <div className="flex" data-aos="left-right" data-aos-delay="3000" data-aos-easing="ease-in-out">
            <button
              type="button"
              onClick={handleButtonClick}

              className="flex items-center justify-center mt-3 w-[14rem] p-3 bg-[#dda556] hover:bg-yellow-700 text-black font-semibold focus:outline-none focus:ring-2 focus:ring-[#dda556]"
            >
              <span>Explore Opportunities</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="[#dda556]"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                className="ml-2 w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a7 7 0 1014 0A7 7 0 001 8zm15 0A8 8 0 110 8a8 8 0 0116 0zM4.5 7.5a.5.5 0 000 1h5.793l-2.147 2.146a.5.5 0 00.708.708l3-3a.5.5 0 000-.708l-3-3a.5.5 0 10-.708.708L10.293 7.5H4.5z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <Modalform isModalOpen={isModalOpen} handleCloseModal={handleCloseModal} />

    </div>
    </div>
  );
};

export default Jointheteam;