import React, { useEffect } from "react";
import bgimg from "../Assets/Images/logos/bgimage.png";
import logo1 from "../Assets/Images/logos/sattva.png";
import logo2 from "../Assets/Images/logos/Sriram.png";
import logo3 from "../Assets/Images/logos/Lodha.png";
import logo4 from "../Assets/Images/logos/Adarsh.png";
import logo5 from "../Assets/Images/logos/Godrej.png";
import logo6 from "../Assets/Images/logos/sowparnika.png";

import "../App.css";

const PowerPartners = () => {
  
  return (
    <div className="container-fluid px-4">
      <div className="">
        <div className="text-center">
          <div className="flex flex-row justify-center">
            <div
              data-aos="new-up"
              data-aos-delay="2800"
              data-aos-easing="ease-in-out"
            >
              <h2 className="text-3xl font-medium text-[#DDA556] ">Power </h2>
            </div>
            <div
              data-aos="new-up"
              data-aos-delay="2900"
              data-aos-easing="ease-in-out"
            >
              <h2 className="text-3xl font-medium text-[#DDA556] ml-2">
                {" "}
                Partners
              </h2>
            </div>
          </div>
        </div>
        <div
          className="relative mt-6 rounded-lg circle"
          data-aos="flip-down"
          data-aos-delay="3000"
          data-aos-easing="ease-in-out"
        >
          <div
            className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-6 gap-4 p-4 bg-cover bg-center bg-no-repeat rounded-3xl lg:p-5 example-5 line svg "
            style={{ backgroundImage: `url(${bgimg})` }}
          >
            <div className="flex justify-center items-center w-full h-20 md:h-40 lg:h-32">
              <img
                src={logo1}
                alt="Logo 1"
                className="w-full h-full object-contain p-2"
              />
            </div>
            <div className="flex justify-center items-center w-full h-24 md:h-40 lg:h-36">
              <img
                src={logo2}
                alt="Logo 2"
                className="w-full h-full object-contain p-2"
              />
            </div>
            <div className="flex justify-center items-center w-full h-24 md:h-40 lg:h-36">
              <img
                src={logo3}
                alt="Logo 3"
                className="w-full h-full object-contain p-2"
              />
            </div>
            <div className="flex justify-center items-center w-full h-24 md:h-40 lg:h-36">
              <img
                src={logo4}
                alt="Logo 4"
                className="w-full h-full object-contain p-2"
              />
            </div>
            <div className="flex justify-center items-center w-full h-24 md:h-40 lg:h-36 ">
              <img
                src={logo5}
                alt="Logo 5"
                className="w-full h-full object-contain p-2"
              />
            </div>
            <div className="flex justify-center items-center w-full h-24 md:h-40 lg:h-36 ">
              <img
                src={logo6}
                alt="Logo 5"
                className="w-full h-full object-contain p-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerPartners;
